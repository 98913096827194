import { all, takeEvery, put, fork } from 'redux-saga/effects'
import { push } from 'react-router-redux'
import { getToken, clearToken } from '../../helpers/utility'
import actions from './actions'
import { post } from '../../transport'

const fakeApiCall = false // auth0 or express JWT

export function* loginRequest() {
  yield takeEvery('LOGIN_REQUEST', function*(action) {
    const { email, password } = action.payload

    try {
      const res = yield post({ cmd: 'login', email, password })
      const { session, viewer } = res.body

      yield put({
        type: actions.LOGIN_SUCCESS,
        token: session.token,
        profile: viewer
      })
    } catch (error) {
      yield put({ type: actions.LOGIN_ERROR })
    }
  })
}

export function* loginSuccess() {
  yield takeEvery(actions.LOGIN_SUCCESS, function*(payload) {
    yield localStorage.setItem('id_token', payload.token)
  })
}

export function* loginError() {
  yield takeEvery(actions.LOGIN_ERROR, function*() {})
}

export function* logout() {
  yield takeEvery(actions.LOGOUT, function*() {
    clearToken()
    yield put(push('/'))
  })
}
export function* checkAuthorization() {
  yield takeEvery(actions.CHECK_AUTHORIZATION, function*() {
    const token = getToken().get('idToken')

    if (token) {
      try {
        const res = yield post({ cmd: 'login', token })
        const { session, viewer } = res.body

        yield put({
          type: actions.LOGIN_SUCCESS,
          token: session.token,
          profile: viewer
        })
      } catch (error) {
        yield put({
          type: actions.LOGOUT
        })
      }
    }
  })
}
export default function* rootSaga() {
  yield all([
    fork(checkAuthorization),
    fork(loginRequest),
    fork(loginSuccess),
    fork(loginError),
    fork(logout)
  ])
}
