import React from 'react'
import { Select } from 'antd'
import { StyledSelect } from '../Forms'

const { Option } = Select

const PortalSelect = ({
  placeholder,
  displayKey,
  disabled,
  dataKey,
  useValue, // BC, TODO: need to clean up
  useId, // for BC, TODO: remove when update all selectors to use id
  options,
  setFieldValue,
  initialValue,
  onSearch,
  onChange,
  value,
  filterOption
}) => {
  dataKey = dataKey ? dataKey : displayKey
  onChange = onChange || defaultOnChange

  function defaultOnChange(value) {
    const data = options[value]
    setFieldValue(dataKey, data)
  }

  return (
    <StyledSelect
      showSearch
      disabled={!!disabled}
      placeholder={placeholder}
      onChange={onChange}
      onSearch={onSearch}
      filterOption={filterOption}
      defaultValue={initialValue}
    >
      {options.map((option, index) => {
        return (
          <Option value={useId ? option.id : index} key={option.id}>
            {option[displayKey]}
          </Option>
        )
      })}
    </StyledSelect>
  )
}

export default PortalSelect
