import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Avatar } from 'antd'
import Popover from '@isomorphic/shared/isomorphic/components/uielements/popover'
import IntlMessages from '@isomorphic/shared/isomorphic/components/utility/intlMessages'
import userpic from '@isomorphic/shared/isomorphic/assets/images/user1.png'
import authAction from '../../redux/auth/actions'
import TopbarDropdownWrapper from './topbarDropdown.style'

const { logout } = authAction

class TopbarUser extends Component {
  constructor(props) {
    super(props)
    this.handleVisibleChange = this.handleVisibleChange.bind(this)
    this.hide = this.hide.bind(this)
    this.state = {
      visible: false
    }
  }
  hide() {
    this.setState({ visible: false })
  }
  handleVisibleChange() {
    this.setState({ visible: !this.state.visible })
  }

  render() {
    const content = (
      <TopbarDropdownWrapper className="isoUserDropdown">
        {/*
        <a className="isoDropdownLink" href="# ">
          <IntlMessages id="themeSwitcher.settings" />
        </a>
        <a className="isoDropdownLink" href="# ">
          <IntlMessages id="sidebar.feedback" />
        </a>
        <a className="isoDropdownLink" href="# ">
          <IntlMessages id="topbar.help" />
        </a>
        */}
        <a className="isoDropdownLink" onClick={this.props.logout} href="# ">
          <IntlMessages id="topbar.logout" />
        </a>
      </TopbarDropdownWrapper>
    )

    return (
      <Popover
        content={content}
        trigger="click"
        visible={this.state.visible}
        onVisibleChange={this.handleVisibleChange}
        arrowPointAtCenter={true}
        placement="bottomLeft"
      >
        <div className="isoImgWrapper">
          <Avatar
            style={{
              backgroundColor: `#${this.props.viewer.avatar.color}`
            }}
            size="large"
          >
            {this.props.viewer.avatar.initials}
          </Avatar>
          <span className="userActivity online" />
        </div>
      </Popover>
    )
  }
}
export default connect(
  state => ({ viewer: state.Auth.viewer }),
  { logout }
)(TopbarUser)
