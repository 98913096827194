import React, { useState, useEffect } from 'react'
import { Button, Icon, Row, Col } from 'antd'
import { Formik, Field } from 'formik'
import * as Yup from 'yup';

import { post } from '../../transport'
import { useCover } from '../../context/cover'

import DialogWrapper, { StyledTextArea, StyledCommisionRow, StyledIconCol, rowStyle, confirmDeleteRowStyle, StyledConfirmDelete, StepTitle, StyledTitle, StyledParagraph } from './style'
import { StyledField, FormInner, FieldError } from '../Forms'
import { Wrap } from '../DataDisplay/Layout/Layout.style'
import PortalSelect from '../PortalSelect';

const CustomTextArea = ({ rows, placeholder, id, className, onChange, value }) => {
  return (
    <StyledTextArea
      className={className}
      id={id}
      rows={rows}
      placeholder={placeholder}
      onChange={onChange}
      value={value}
    />
  )
}

const createOwners = (
  ownerId,
  ownerCommission,
  ownerName,
  additionalOwnerId,
  additionalOwnerCommission,
  additionalOwnerName
) => {
  const primaryOwner = {
    id: ownerId,
    commission: ownerCommission,
    name: ownerName,
    primary: true
  }

  const additionalOwner = {
    id: additionalOwnerId,
    commission: additionalOwnerCommission,
    name: additionalOwnerName,
    primary: false
  }

  let owners = []
  owners.push(primaryOwner, additionalOwner)
  return owners
}

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required('Advertiser name is required.'),
  notes: Yup.string(),
  owner: Yup.string(),
  ownerCommission: Yup.number()
    .max(3, 'The commission cannot be higher than 100')
    .lessThan(100, 'The commission cannot be greater than or equal to 100')
    .moreThan(-1, 'The number must be zero or greater.')
    .integer('Please specifify a whole number.'),
  additionalOwner: Yup.string(),
  additionalOwnerCommission: Yup.number()
    .max(3, 'The commission cannot be higher than 100')
    .lessThan(100, 'The commission cannot be greater than or equal to 100')
    .moreThan(-1, 'The number must be zero or greater.')
    .integer('Please specifify a whole number.'),
  hubspotID: Yup.string(),
  hubspotLink: Yup.string()
    .url('Please enter a valid URL')
})

const CreateAdvertiserDialog = ({ advertiserId, onAdvertiserChange }) => {
  const { closeCoverScreen } = useCover()
  const [shouldDelete, setShouldDelete] = useState(false)
  const [owners, setOwners] = useState([])
  const [advertiser, setAdvertiser] = useState({
    additionalOwnerCommission: '',
    additionalOwnerId: '',
    additionalOwnerName: '',
    avatar: '',
    createdAt: '',
    hubspotId: '',
    hubspotLink: '',
    id: '',
    name: '',
    ownerCommission: '',
    ownerId: '',
    ownerName: '',
    refId: '',
    splints: '',
    status: '',
    tz: ''
  })


  useEffect(() => {
    if(advertiserId) {
      fetchAdvertiser()
    }
  }, [])

  useEffect(() => {
    fetchOwners()
  }, [])

  function fetchAdvertiser() {
    post({ get: 'advertiser', id: advertiserId }).then(
      out => {
        const data = out.body
        data.commission = data.commission || 0
        data['hubspotId'] = data.hubspot && data.hubspot.id ? data.hubspot.id : ''
        data['hubspotLink'] = data.hubspot && data.hubspot.link ? data.hubspot.link : ''
        flattenOwnersToProperty(data)
        setAdvertiser(data)
      },
      e => {
        console.error('CreatePropertyDialog->fetchOwners()->error', e)
      }
    )
  }

  function fetchOwners() {
    post({ list: 'users', filter: { type: 'operator' } }).then(
      out => {
        const data = out.body.data
        setOwners(data)
      },
      e => {
        console.error('CreatePropertyDialog->fetchOwners()->error', e)
      }
    )
  }

  function flattenOwnersToProperty(item) {
    if (item.owners && item.owners.length > 0) {
      for (let owner of item.owners) {
        if (owner.primary) {
          item['ownerId'] = owner.id
          item['ownerName'] = owner.name
          item['ownerCommission'] = owner.commission || ''
        } else {
          item['additionalOwnerId'] = owner.id
          item['additionalOwnerName'] = owner.name
          item['additionalOwnerCommission'] = owner.commission || ''
        }
      }
    }
  }

  function renderDeleteConfirmation() {
    return (
      <Row style={confirmDeleteRowStyle} type="flex" gutter={24}>
        <Col>
          <Button
            onClick={() => setShouldDelete(false)}
          >
            Cancel
          </Button>
        </Col>
        <Col>
          <StyledConfirmDelete
            onClick={handleDelete}
          >
            Confirm Delete
          </StyledConfirmDelete>
        </Col>
      </Row>
    )
  }

  function handleDelete() {
    const msg = {
      role: 'web',
      remove: 'advertiser',
      id: advertiserId
    }
    post(msg).then(
      out => {
        onAdvertiserChange(out.body)
        closeCoverScreen()
      },
      e => {
        console.error('CreatePublisherDialog->handleDelete->error', e)
      }
    )
  }

  function onSubmit(values, { setSubmitting }) {
    const owners = createOwners(
      values.ownerId,
      values.ownerCommission,
      values.ownerName,
      values.additionalOwnerId,
      values.additionalOwnerCommission,
      values.additionalOwnerName
    )
    let msg
    if (advertiserId) {
      msg = {
        update: 'advertiser',
        ...values,
        owners: owners
      }
    } else {
      msg = {
        create: 'advertiser',
        owners: owners,
        ...values
      }
    }

    post(msg).then(out => {
      setSubmitting(false)
      onAdvertiserChange(out.body)
      closeCoverScreen()
    })
  }

  return (
    <DialogWrapper>
      <Wrap>
        <StepTitle>
          <StyledTitle level={1}>{advertiserId ? 'Edit Advertiser' : 'Create Advertiser'}</StyledTitle>
          <StyledParagraph type="secondary">Select and fill in the fields below to create an advertiser.</StyledParagraph>
        </StepTitle>
        <Formik
          enableReinitialize={true}
          initialValues={advertiser}
          onSubmit={onSubmit}
          validationSchema={validationSchema}>
          {({ values, errors, touched, handleChange, handleSubmit, isSubmitting, setFieldValue }) => (
            <form onSubmit={handleSubmit} autoComplete="off">
              <FormInner>
                <StyledParagraph strong>Enter Advertiser Name</StyledParagraph>
                <StyledField
                  type="text"
                  name="name"
                  placeholder="Enter Advertiser Name"
                  onChange={handleChange}
                  value={values.name}
                />
                {errors.name && touched.name && (
                  <FieldError>{errors.name}</FieldError>
                )}
                <StyledParagraph strong>Advertiser Notes</StyledParagraph>
                <Field 
                  component={CustomTextArea}
                  id="notes"
                  placeholder="Advertiser Notes"
                  onChange={handleChange}
                  rows={4}
                  className="text-area"
                  value={values.notes}
                />
                <StyledParagraph strong>Select Owner</StyledParagraph>
                <Field
                  component={PortalSelect}
                  options={owners}
                  displayKey="name"
                  dataKey="owner"
                  placeholder="Select Owner"
                  onChange={(value) => {
                    values.ownerName = owners[value].name
                    values.ownerId = owners[value].id
                  }}
                  filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  initialValue={values.ownerName}
                />
                {errors.owner && touched.owner && (
                  <FieldError>{errors.owner}</FieldError>
                )}
                <StyledParagraph strong>Enter Owner Commission</StyledParagraph>
                <StyledCommisionRow type="flex" style={rowStyle}>
                  <Col span={22}>
                    <StyledField
                      margin="0 0 0 0"
                      type="number"
                      name="ownerCommission"
                      placeholder="Enter Owner Commission"
                      onChange={handleChange}
                      value={values.ownerCommission}
                    />
                  </Col>
                  <StyledIconCol span={2}>
                    <Icon type="percentage" />
                  </StyledIconCol>
                </StyledCommisionRow>
                {errors.ownerCommission && touched.ownerCommission && (
                  <FieldError>{errors.ownerCommission}</FieldError>
                )}
                <StyledParagraph strong>Select Additional Owner</StyledParagraph>
                <Field
                  component={PortalSelect}
                  options={owners}
                  displayKey="name"
                  dataKey="additionalOwner"
                  placeholder="Select Additional Owner"
                  onChange={(value) => {
                    values.additionalOwnerName = owners[value].name
                    values.additionalOwnerId = owners[value].id
                  }}
                  filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  initialValue={values.additionalOwnerName}
                />
                {errors.additionalOwner && touched.additionalOwner && (
                  <FieldError>{errors.additionalOwner}</FieldError>
                )}
                <StyledParagraph strong>Enter Additional Owner Commission</StyledParagraph>
                <StyledCommisionRow type="flex" style={rowStyle}>
                  <Col span={22}>
                    <StyledField
                      margin="0 0 0 0"
                      type="number"
                      name="additionalOwnerCommission"
                      placeholder="Enter Additional Owner Commission"
                      onChange={handleChange}
                      value={values.additionalOwnerCommission}
                    />
                  </Col>
                  <StyledIconCol span={2}>
                    <Icon type="percentage" />
                  </StyledIconCol>
                </StyledCommisionRow>
                {errors.additionalOwnerCommission && touched.additionalOwnerCommission && (
                  <FieldError>{errors.additionalOwnerCommission}</FieldError>
                )}
                <StyledParagraph strong>Hubspot Company ID</StyledParagraph>
                <StyledField
                  type="text"
                  name="hubspotId"
                  placeholder="Hubspot Company ID"
                  onChange={handleChange}
                  value={values.hubspotId}
                />
                <StyledParagraph strong>Hubspot Company Link</StyledParagraph>
                <StyledField
                  type="text"
                  name="hubspotLink"
                  placeholder="Hubspot Company Link"
                  onChange={handleChange}
                  value={values.hubspotLink}
                />
                {errors.hubspotLink && touched.hubspotLink && (
                  <FieldError>{errors.hubspotLink}</FieldError>
                )}
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={isSubmitting}
                >
                  {advertiserId ? 'Update' : 'Create'}
                </Button>
                {/* {
                  advertiserId ? 
                    <Button
                      ghost
                      type="danger"
                      icon="delete"
                      style={deleteStyle}
                      disabled={isSubmitting}
                      onClick={() => setShouldDelete(true)}
                    >
                      Delete
                    </Button>
                  :
                  null
                } */}
                {
                  shouldDelete ? renderDeleteConfirmation() : null
                }

              </FormInner>
            </form>
          )}
        </Formik>
      </Wrap>
    </DialogWrapper>
  )
}

export default CreateAdvertiserDialog
