import * as storage from 'store'
import request from 'superagent'

const API_HOST = process.env.REACT_APP_API_HOST
const url = `${API_HOST}/web`

const post = msg => {
  const token = storage.get('id_token') || null

  return request
    .post(url)
    .send({ role: 'web', ...msg })
    .set('Authorization', token)
}

export { post }
