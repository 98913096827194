const account = [
  {
    key: 'campaigns',
    label: 'Campaigns',
    leftIcon: 'ion-images'
  }
  /*{
    key: 'submissions',
    label: 'Submissions',
    leftIcon: 'ion-paper-airplane'
  }*/
]

const admin = [
  {
    key: 'advertisers',
    label: 'Advertisers',
    leftIcon: 'fa fa-bullhorn'
  },
  {
    key: 'publishers',
    label: 'Publishers',
    leftIcon: 'fa fa-building'
  },
  {
    key: 'properties',
    label: 'Properties',
    leftIcon: 'fa fa-street-view'
  },
  {
    key: 'proposals',
    label: 'Proposals',
    leftIcon: 'fas fa-file-contract'
  },
  {
    key: 'insertionOrders',
    label: 'Insertion Orders',
    leftIcon: 'fas fa-file-invoice-dollar'
  },
  {
    key: 'leads',
    label: 'Leads',
    leftIcon: 'fas fa-paper-plane'
  },
  {
    key: 'identity-graph',
    label: 'Identity Graph',
    leftIcon: 'fa fa-fingerprint'
  },
  {
    key: 'team',
    label: 'Team Members',
    leftIcon: 'ion-ios-people'
  },
  {
    key: 'import-export',
    label: 'Import/Export',
    leftIcon: 'ion-pull-request'
  }
]

const options = {
  account,
  admin
}
export default options
