import actions from './actions'

const initState = null

export default function accountReducer(state = initState, action) {
  switch (action.type) {
    case actions.SET_ACCOUNT:
      return { ...state, ...action.payload }
    case actions.LOGOUT:
      return initState
    default:
      return state
  }
}
