import React, { Component } from 'react'
import { Route, Redirect } from 'react-router-dom'
import asyncComponent from '../../helpers/AsyncFunc'

const routes = [
  {
    path: '',
    component: ({ match }) => <Redirect to={`${match.url}/campaigns`} />
  },
  {
    path: 'advertisers',
    component: asyncComponent(() => import('../Advertisers/Advertisers'))
  },
  {
    path: 'campaigns',
    component: asyncComponent(() => import('../Campaigns/Campaigns'))
  },
  {
    path: 'campaigns/:id',
    component: asyncComponent(() => import('../Campaigns/Campaign'))
  },
  {
    path: 'submissions',
    component: asyncComponent(() => import('../Submissions/Submissions'))
  },
  {
    path: 'import-export',
    component: asyncComponent(() => import('../ImportExport/ImportExport'))
  },
  {
    path: 'identity-graph',
    component: asyncComponent(() => import('../IdentityGraph/IdentityGraph'))
  },
  {
    path: 'publishers',
    component: asyncComponent(() => import('../Publishers/Publishers'))
  },
  {
    path: 'properties',
    component: asyncComponent(() => import('../Properties/Properties'))
  },
  {
    path: 'proposals',
    component: asyncComponent(() => import('../Proposals/Proposals'))
  },
  {
    path: 'leads',
    component: asyncComponent(() => import('../Leads'))
  },
  {
    path: 'identity-graph/:id',
    component: asyncComponent(() => import('../IdentityGraph/Profile'))
  },
  {
    path: 'team',
    component: asyncComponent(() => import('../Team/Team'))
  }
]

class AppRouter extends Component {
  render() {
    const { url, style } = this.props
    return (
      <div style={style}>
        {routes.map(singleRoute => {
          const { path, exact, ...otherProps } = singleRoute
          return (
            <Route
              exact={exact === false ? false : true}
              key={singleRoute.path}
              path={`${url}/${singleRoute.path}`}
              {...otherProps}
            />
          )
        })}
      </div>
    )
  }
}

export default AppRouter
