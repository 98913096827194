import React, { Component, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import * as storage from 'store'
import queryString from 'query-string'
import find from 'lodash/find'
import Popover from '@isomorphic/shared/isomorphic/components/uielements/popover'
import IntlMessages from '@isomorphic/shared/isomorphic/components/utility/intlMessages'
import userpic from '@isomorphic/shared/isomorphic/assets/images/user1.png'
import accountAction from '../../redux/account/actions'
import SidebarDropdownWrapper from './SidebarAccountDropdown.style'
import { Tooltip, Avatar, Icon } from 'antd'
import { post } from '../../transport'
import { useCover } from '../../context/cover'
import CreateAdvertiserDialog from '../../components/CreateAdvertiserDialog'

const { setAccount } = accountAction

const AccountsDropdopdown = ({ accounts, onSelect, hideSelector }) => {
  const { openCoverScreen } = useCover()
  const [accountOptions, setAccountOptions] = useState(accounts || [])

  function handleFilterAccounts(query) {
    const filterReducer = (acc, account) => {
      if (account.adv_name.toLowerCase().indexOf(query.toLowerCase()) > -1) {
        acc.push(account)
      }
      return acc
    }

    if (!query) {
      setAccountOptions(accounts)
    } else {
      setAccountOptions(accounts.reduce(filterReducer, []))
    }
  }

  function handleCreateNewAccount() {
    hideSelector()
    openCoverScreen(<CreateAdvertiserDialog onAdvertiserChange={onSelect} />)
  }

  return (
    <SidebarDropdownWrapper
      className="isoAccountDropdown"
      style={{ maxHeight: 700, overflow: 'hidden', overflowY: 'auto' }}
    >
      <div className="AccountDropDownSearch">
        <input
          placeholder="Search"
          onChange={e => handleFilterAccounts(e.target.value)}
        ></input>
      </div>

      {accountOptions.map(acct => (
        <a
          key={acct.advid}
          className="isoDropdownLink"
          onClick={() => onSelect(acct)}
        >
          <div className="isoImgWrapper">
            <Avatar
              style={{
                backgroundColor: `#${(acct.avatar && acct.avatar.color) ||
                  'B6B8BF'}`
              }}
              size="large"
            >
              {(acct.avatar && acct.avatar.initials) ||
                (acct.adv_name[0] || '').toString() ||
                'A'}
            </Avatar>
          </div>
          <span className="isoAccountDropdownName">{acct.adv_name}</span>
        </a>
      ))}
      {/*
      <a className="isoDropdownLink" onClick={handleCreateNewAccount}>
        <div className="isoImgWrapper">
          <Avatar style={{ backgroundColor: `#999` }} size="large">
            <Icon type="plus"></Icon>
          </Avatar>
        </div>
        <span className="isoAccountDropdownName">Create Advertiser</span>
      </a>
      */}
    </SidebarDropdownWrapper>
  )
}

class SidebarAccount extends Component {
  constructor(props) {
    super(props)
    this.handleVisibleChange = this.handleVisibleChange.bind(this)
    this.hide = this.hide.bind(this)
    this.handleAccountSelect = this.handleAccountSelect.bind(this)
    this.state = {
      loading: true,
      visible: false,
      accounts: []
    }
  }

  componentDidMount() {
    const query = queryString.parse(this.props.location.search)
    const id = query.aid || storage.get('current_account')

    this.fetchAdvertisers().then(list => {
      this.setState({ loading: false })

      if (!list.length) return

      let account = list[0]

      if (id) {
        account = find(list, { advid: id }) || account
      }

      this.props.setAccount(account)
      this.props.history.push(
        `${this.props.location.pathname}?${queryString.stringify(
          Object.assign({}, query, {
            aid: account.advid
          })
        )}`
      )
    })
  }

  fetchAdvertisers() {
    return post({ list: 'advertiser' }).then(res => {
      this.setState({ accounts: res.body.data })
      return Promise.resolve(res.body.data)
    })
  }

  hide() {
    this.setState({ visible: false })
  }

  handleVisibleChange() {
    this.setState({ visible: !this.state.visible })
  }

  handleAccountSelect(data) {
    this.props.setAccount(data)
    this.hide()
    this.props.history.push(
      `${this.props.match.url}/campaigns?aid=${data.advid}`
    ) // TODO: needs to be configured
  }

  render() {
    const { account } = this.props
    const content = (
      <AccountsDropdopdown
        accounts={this.state.accounts}
        onSelect={data => {
          this.handleAccountSelect(data)
          this.fetchAdvertisers()
        }}
        hideSelector={this.hide}
      />
    )

    let avatar

    if (!account) {
      avatar = {
        color: 'B6B8BF',
        initials: this.state.loading ? (
          <Icon type="loading" />
        ) : (
          <Icon type="plus" />
        )
      }
    } else {
      avatar = {
        color: (account.avatar && account.avatar.color) || 'B6B8BF',
        initials:
          (account.avatar && account.avatar.initials) ||
          (account.adv_name[0] || '').toString() ||
          'A'
      }
    }

    return (
      <Popover
        content={content}
        trigger="click"
        visible={this.state.visible}
        onVisibleChange={this.handleVisibleChange}
        arrowPointAtCenter={true}
        placement="rightTop"
        overlayClassName="sidebar-account-selector"
        align={{ offset: [-65, -30] }}
      >
        <Tooltip title={account ? account.name : ''} placement="right">
          <div className="isoImgWrapper">
            <Avatar
              style={{
                backgroundColor: `#${avatar.color}`,
                border: '1px solid white'
              }}
              size="large"
            >
              {avatar.initials}
            </Avatar>
          </div>
        </Tooltip>
      </Popover>
    )
  }
}
export default connect(
  state => ({
    account: state.account || null
  }),
  { setAccount }
)(withRouter(SidebarAccount))
