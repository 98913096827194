import * as storage from 'store'
import actions from './actions'

const accountMiddleware = store => next => action => {
  if (action.type === actions.SET_ACCOUNT) {
    storage.set('current_account', action.payload ? action.payload.advid : null)
  }
  next(action)
}

export default accountMiddleware
