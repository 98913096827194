import styled from 'styled-components'
import { Input, Button, Row, Col, Typography } from 'antd'

const { TextArea } = Input
const { Title, Paragraph } = Typography;

const DialogWrapper = styled.div`
  display: flex;
  width: 60%;
  padding-top: 3%;
  margin: 0 auto;
`

export const StyledTextArea = styled(TextArea)`
  && {
    &.text-area {
      margin-bottom: 20px;
    }
  }
`

export const StyledCommisionRow = styled(Row)`
  margin-bottom: 20px;
`

export const StyledIconCol = styled(Col)`
  display: flex !important;
  justify-content: center;
  align-items: center;
  background: white;
  border: 1px solid #ececec;
  border-radius: 2px;
`

export const rowStyle = {
  width: '100%'
}

export const confirmDeleteRowStyle = {
  marginTop: '2rem'
}

export const StyledConfirmDelete = styled(Button)`
  && {
    background-color: #f5222d;
    color: white;
    &:hover {
      background-color: #f5222d;
      border: #f5222d;
      color: white;
      opacity: .5;
    }
  }
`

export const StepTitle = styled.div`
  max-width: 600px;
  text-align: center;
  margin: auto;
`

export const StyledTitle = styled(Title)`
  color: #2f2f2f;
  margin: ${props => props.margin ? props.margin : '0 0 10px 0'}
`

export const StyledParagraph = styled(Paragraph)`
  width: 100%;
`

export default DialogWrapper
